html .topbar {
    background-color: $toolbar;
    position: sticky;
    top: 0;
    z-index: 9;
    height: $header-height;
}

.topbar-dd {
    min-width: 360px !important;
}

.topbar-dd-lg {
    min-width: 460px !important;
}

.apps-dd {
    min-width: 830px !important;
    overflow: unset !important;
    .mat-mdc-menu-content {
        padding: 0;
    }
}

.upgrade-bg {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
}

.object-cover {
    object-fit: cover;
}

@media (max-width: 768px) {
    .topbar-dd-lg {
        min-width: 380px !important;
    }
}

@media (min-width: 768px) {
    .search-dialog {
        width: 600px;
    }
}

// perfect scroll
.ps__rail-y {
    right: 0;
    left: unset !important;
}

.profile-dd {
    margin-top: -5px;
    margin-left: -5px;
}